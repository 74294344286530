<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Create Equity Incentive Plan'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Create Equity Incentive Plan</span>
      </v-card-title>
      <common-page-loader v-if="loading" />
      <common-trs-card class="mx-5 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="px-0">
            <eip-form
              v-model="eipData"
              :eip-id="eipId"
              :share-terms="shareTermList"
              :is-submit="isSubmit"
              @update="update"
              @navToList="navToList"
            />
          </v-card-text>
          <v-row class="mx-1">
            <v-col>
              <v-divider />
              <div class="d-flex justify-space-between align-center pt-3">
                <common-trs-btn
                  type="secondary"
                  medium
                  class="pull-right mr-2"
                  text
                  :to="{name: 'EquityIncentivePlans'}"
                >
                  Cancel
                </common-trs-btn>
                <common-trs-btn
                  type="primary"
                  medium
                  class="white--text pull-right"
                  text
                  :disabled="!valid"
                  :loading="loading"
                  @click="save"
                >
                  Save
                </common-trs-btn>
              </div>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import EipForm from '@/components/common/captable/components/forms/EipForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'CreateEIPIndex',

    components: {
      EipForm,
    },

    data: () => ({
      loading: false,
      eipData: {},
      shareTermList: [],
      eipId: '',
      isSubmit: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },

    mounted () {
      this.getShareTermList()
    },

    methods: {
      async getShareTermList () {
        try {
          this.loading = true
          const resp = await captableService.getShareTermList(this.captableId)
          if (resp && resp.data) {
            this.shareTermList = resp.data.share_terms
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loading = true
          const resp = await captableService.draftEip(this.captableId, this.eipData)
          if (resp.status === 201) {
            this.eipId = resp.data.id
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save equity incentive plan. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Equity incentive plan saved successfully.' })
        this.$router.push({ name: 'EquityIncentivePlans' })
      },
      close () {
        this.$router.push({ name: 'EquityIncentivePlans' })
      },
      update (data) {
        this.eipData = data
      },
    },
  }
</script>
